<template>
  <main class="main page supporting about CMS_pages">
    <div class="heroBanner">
      <img src="../assets/images/heroes/about-hero-bg.jpg">
      <h1 class="section__heading">About toTree</h1>
    </div>
    <section class="section" id="story">
      <div class="text">
        <!-- <img src="@/assets/images/trees.png" style="grid-column: span 12"> -->
        <h2 class="testimonial">Eight years ago, the woman I was dating stood in my living room and made an announcement. “I can’t take it anymore,” she declared. “This has to change. Now.”</h2>
        <p>She wasn’t referring to our relationship. She was talking about my furniture. I had a fantastic mattress, but it sat on a flimsy metal frame (on wheels!). My dresser was a college relic. The living room couch sagged in the middle and my coffee table looked like it had been pulled from a dumpster. In my defense, I pointed out the state-of-the-art TV and the sleek framed photographs hanging on the walls. No luck. You’re thirty-five, she explained. There’s simply no excuse.</p>
        <p>But I did have an excuse. I’d wanted to upgrade my furniture for a long time. I had the money to do it and had a solid idea of my preferred aesthetic. But where to start? I didn’t have a clue about furniture. Where to buy it, what to buy, how it all might look and fit into my place. Not to mention the fear of overpaying or getting something that would fall apart in a year or the worst: having to return furniture shipped to my apartment. The few online shopping attempts I made lasted all of twenty minutes. Too many choices, not enough knowledge. And the thought of spending my weekends going to furniture stores instead of playing tennis or hiking or doing something (anything!) I enjoyed? Nothing sounded worse. An interior designer would help, but that sounded expensive, time consuming, and I hoped to go through life never having to look at a bunch of swatches. I wanted an upgrade to my apartment, but I just wanted it fast, easy and reliable.</p>
        <p>That Saturday, my ex took me shopping. She knew exactly where to go, what to buy. It took all of an hour. Two weeks later I had a new bed, dresser, couch, coffee table and sideboard. The furniture wasn’t cheap, but the quality was exceptional. The style, perfect. And every day since, I’ve reaped the benefits. I’ve been more comfortable, confident, and productive. Friends, co-workers and girlfriends have all been impressed. Who knew it could be that simple?</p>
        <p>The ex and I didn’t work out. But what she did for me that Saturday has lasted forever. From that, toTree was born. </p>
        <p><br>Tim Struby<br>Founder &amp; CEO</p>
      </div>
    </section>

    <section class="section sectionContiner" id="team">
      <h1 class="section__heading">Our Team</h1>
      <div class="section__column section__column--2">
      </div>
      <div class="section__column section__column--4 teamBox">
        <div class="text">
          <img src="@/assets/images/headshot-struby.jpg">
          <h3>Tim Struby – CEO &amp; Founder</h3>
          <p>Prior to founding toTree, Tim Struby was an award-winning journalist whose work regularly appeared in magazines including ESPN the Magazine, the New Yorker, New York, Playboy, Victory Journal and Outside. He’s also suffered the pain of furniture shopping more times than he likes to recall.</p>
        </div>
      </div>
      <div class="section__column section__column--4 teamBox">
        <div class="text">
          <img src="@/assets/images/headshot-fisher.jpg">
          <h3>Lyndsey Fisher – Creative Director</h3>
          <p>Creative Director Lyndsey Fisher spent most of her professional career as a partner and designer at Home Frosting, a Florida-based interior design and home staging firm.</p>
        </div>
      </div>
<!--      <div class="section__column section__column&#45;&#45;4 teamBox">-->
<!--        <div class="text">-->
<!--          <img src="@/assets/images/headshot-kolmer.jpg">-->
<!--          <h3>Lloyd Kolmer – Chief Logistics Officer</h3>-->
<!--          <p>Lloyd Kolmer has over 30-years’ experience in both logistics and the moving business.</p>-->
<!--        </div>-->
<!--      </div>-->
    </section>

    <section class="section sectionContiner" id="brands">
      <h1 class="section__heading">Brands</h1>
      <div class="text">
        <p>We believe that where you get your furniture is as important as what you get. Through tireless research, we’ve chosen to offer a handful of brands that share toTree’s core tenets: quality, value and a commitment to a greener planet.</p>
      </div>
      <div class="partners">
        <div class="partners__item"><img src="@/assets/images/brands/article.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/benchmade.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/bludot.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/burrow.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/eq3.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/roomandboard.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/sabai.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/surya.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/thuma.png"></div>
        <div class="partners__item"><img src="@/assets/images/brands/westelm.png"></div>
      </div>
    </section>

  </main>
</template>

<style lang="scss" scoped>
.hero__image {
  background-image: url('../assets/images/heroes/header-about.jpg');
}
.partners {
  padding-top: 1rem;
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: var(--gutter);

  @include breakpoint($s) {
    grid-template-columns: repeat(6, 1fr);
  }

  // centers last row
  @include breakpoint($m-up) {
    &::before {
      content: '';
      width: 0;
      padding-bottom: 0;
      grid-row: 3 / 4;
      grid-column: 1 / 4;
    }
  }

  .partners__item {
    grid-column: span 3;
    line-height: 0;
    display: flex;
    padding: 3rem;
    @media (max-width: 1024px) {
      padding: 1rem;
    }
  }
}
</style>
<script setup>
</script>
